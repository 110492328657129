import React, { useState, useEffect } from "react";
import {
  Button,
  Pagination,
  Typography,
  Grid,
  SvgIcon,
  Box,
  Drawer,
  Divider,
  PaginationItem,
} from "@mui/material";
import "./ReViewExam.scss"; // For additional styling
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { useAppDispatch, useAppSelector } from "../../Redux/Store/Hooks";
import Chatbot from "../../Components/Chatbot/Chatbot";
import Correctanswer from "../../Assets/Images/Correctanswer.svg";
import Wronganswer from "../../Assets/Images/Wronganswer.svg";
import { ReactComponent as Chatbotboticon } from "../../Assets/Images/chatbot 1.svg";
import { successChatbot } from "../../Redux/Slices/Chatbot/ChatbotSlicer";
import { listReViewExamID } from "../../Redux/Slices/ReViewExam/ListReViewExamSlice";
import AlterDialog from "../../Components/AlterDialog/AlterDialog";
import ButtonField from "../../Components/Button/ButtonField";
import { useLocation, useNavigate } from "react-router-dom";

interface Question {
  id: number;
  question_type: string;
  question: string;
  question_url: string;
  prompt: string;
  instruction: string;
  options: {
    optionname: string;
    option_id: number;
    is_correct: boolean;
    chosen: boolean;
    comment: string | null;
  }[];
  optionsurl: {
    optionname: string;
    option_id: number;
    is_correct: boolean;
    chosen: boolean;
    comment: string | null;
  }[];
}

const ReViewExam: React.FC = () => {
  // overall data list
  const { data: listReViewExamIDSlice } = useAppSelector(
    (state) => state.ListReViewExamIDSlice
  );
  const List: any = listReViewExamIDSlice;
  const navigate = useNavigate();
  const { state: view_id } = useLocation();

  //   dialog box
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  function handleListItemClick(question_type_id: number) {
    const questionIndex = List?.data?.questions.findIndex(
      (question: any) => question.question_type_id === question_type_id
    );
    setCurrentQuestion(questionIndex + 1);
  }

  useEffect(() => {
    const filteredQuestionType = List?.data?.questions?.map(
      (question: any) => ({
        id: question?.question_id,
        question_type: question?.question_type_name,
        question_url: question?.question_url,
        question: question?.question_text,
        prompt: question?.prompt,
        instruction: question?.instruction,
        options: question?.options.map((opt: any) => ({
          optionname: opt?.option_text,
          option_id: opt?.option_id,
          is_correct: opt?.is_correct,
          chosen: opt?.chosen,
          comment: opt?.comment,
        })),
        optionsurl: question?.options.map((opt: any) => ({
          optionname: opt?.option_url,
          option_id: opt?.option_id,
          is_correct: opt?.is_correct,
          chosen: opt?.chosen,
          comment: opt?.comment,
        })),
      })
    );

    if (filteredQuestionType?.length > 0) {
      setQuestions(filteredQuestionType);
    }
  }, [List]);

  const [currentQuestion, setCurrentQuestion] = useState<number>(1);

  const [questions, setQuestions] = useState<Question[]>([]); // Initialize with predefined data
  const [isExamOver, setIsExamOver] = useState<boolean>(false);

  const submitExam = () => {
    setIsExamOver(true);
  };

  const renderQuestionOptions = (
    questionId: number,
    options: {
      optionname: string;
      option_id: number;
      is_correct: boolean;
      chosen: boolean;
      comment: string | null;
    }[],
    optionsurl: {
      optionname: string;
      option_id: number;
      is_correct: boolean;
      chosen: boolean;
      comment: string | null;
    }[]
  ) => {
    const hasNullUrl = optionsurl.some((option) => option.optionname === null);

    return (
      <Grid
        container
        spacing={1}
        sx={{
          marginBottom: "60px",
          position: "fixed",
          bottom: "0",
          padding: "0% 10% 4% 10%",
          backgroundColor: "white",
        }}
      >
        {hasNullUrl
          ? options.map((option, index) => {
              let message = null;
              let messageColor = "";

              // Check the conditions
              if (option.chosen && option.is_correct) {
                message = (
                  <>
                    <img
                      src={Correctanswer}
                      alt="Correct answer"
                      style={{
                        width: "15px",
                        marginRight: "8px",
                        // height: "10px",
                      }}
                    />
                    Your Answer is Correct
                  </>
                );
                messageColor = "#88B61E";
              } else if (option.chosen && !option.is_correct) {
                message = (
                  <>
                    <img
                      src={Wronganswer}
                      alt="Wrong answer"
                      style={{
                        width: "20px",
                        marginRight: "8px",
                        // height: "10px",
                      }}
                    />
                    Your Answer is Wrong
                  </>
                );
                messageColor = "#BB2B31";
              } else if (!option.chosen && option.is_correct) {
                message = (
                  <>
                    <img
                      src={Correctanswer}
                      alt="Correct answer"
                      style={{
                        width: "20px",
                        marginRight: "8px",
                        // height: "10px",
                      }}
                    />
                    This is Correct Answer
                  </>
                );
                messageColor = "#88B61E";
              }

              return (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      border: "1px solid #CDCDCD",
                      borderRadius: "6px",
                      padding: "5px",
                      textAlign: "center",
                      color: option.chosen
                        ? "white"
                        : option.is_correct
                        ? "white"
                        : "black",
                      backgroundColor: option.chosen
                        ? option.is_correct
                          ? "#88B61E"
                          : "#BB2B31"
                        : option.is_correct
                        ? "#88B61E"
                        : "white",
                      "&:hover": {
                        backgroundColor: option.chosen
                          ? option.is_correct
                            ? "#88B61E"
                            : "#BB2B31"
                          : option.is_correct
                          ? "#88B61E"
                          : "white",
                      },
                    }}
                  >
                    {option?.optionname}
                  </Box>
                  {/* Render message if available */}
                  {message && (
                    <span style={{ color: messageColor }}>{message}</span>
                  )}
                </Grid>
              );
            })
          : optionsurl.map((option, index) => {
              let message = null;
              let messageColor = "";

              // Check the conditions
              if (option.chosen && option.is_correct) {
                message = (
                  <>
                    <img
                      src={Correctanswer}
                      alt="Correct answer"
                      style={{
                        width: "15px",
                        marginRight: "8px",
                        // height: "10px",
                      }}
                    />
                    Your Answer is Correct
                  </>
                );
                messageColor = "#88B61E";
              } else if (option.chosen && !option.is_correct) {
                message = (
                  <>
                    <img
                      src={Wronganswer}
                      alt="Wrong answer"
                      style={{
                        width: "20px",
                        marginRight: "8px",
                        // height: "10px",
                      }}
                    />
                    Your Answer is Wrong
                  </>
                );
                messageColor = "#BB2B31";
              } else if (!option.chosen && option.is_correct) {
                message = (
                  <>
                    <img
                      src={Correctanswer}
                      alt="Correct answer"
                      style={{
                        width: "20px",
                        marginRight: "8px",
                        // height: "10px",
                      }}
                    />
                    This is Correct Answer
                  </>
                );
                messageColor = "#88B61E";
              }

              return (
                <Grid item xs={6} key={index}>
                  <Box
                    sx={{
                      border: "1px solid #CDCDCD",
                      borderRadius: "6px",
                      padding: "3px",
                      textAlign: "center",
                      color: option.chosen
                        ? "white"
                        : option.is_correct
                        ? "white"
                        : "black",
                      backgroundColor: option.chosen
                        ? option.is_correct
                          ? "#88B61E"
                          : "#BB2B31"
                        : option.is_correct
                        ? "#88B61E"
                        : "white",
                      "&:hover": {
                        backgroundColor: option.chosen
                          ? option.is_correct
                            ? "#88B61E"
                            : "#BB2B31"
                          : option.is_correct
                          ? "#88B61E"
                          : "white",
                      },
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "12px",
                          fontWeight: "400",
                          textTransform: "capitalize",
                        }}
                      >
                        {String.fromCharCode(97 + index)}
                      </span>
                      <img
                        src={option.optionname}
                        alt={`Uploaded ${index}`}
                        style={{ maxWidth: "100%", height: "50px" }}
                      />{" "}
                    </div>
                  </Box>
                  {/* Render message if available */}
                  {message && (
                    <span style={{ color: messageColor }}>{message}</span>
                  )}
                </Grid>
              );
            })}
      </Grid>
    );
  };

  // chatbot
  const [chatbotOpen, setChatbotOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listReViewExamID({ id: view_id }));
  }, [dispatch]);

  // question image show
  const imageFile = questions[currentQuestion - 1]?.question_url;

  return (
    <>
      <div style={{ height: "50px" }} />
      <div className="unselectable">
        <div className="onlineexamdiv">
          <div className="Questiontype">
            <div className="examtext">{List?.data?.exam_name}</div> &nbsp; :
            &nbsp;
            <div className="Questiontypeheading">
              {questions[currentQuestion - 1]?.question_type}
            </div>
            <div className="onlineexmcount">
              Q. {currentQuestion} of {questions?.length}
            </div>
          </div>
          <div className="Questiontype">
            <div className="">
              <Button onClick={toggleDrawer(true)} className="buttonsection">
                Section
              </Button>
            </div>
          </div>
        </div>
        <div className="overonlinediv">
          <Grid item xs={12} md={6}>
            <div className="examscroll">
              <Typography
                style={{
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  overflow: "auto", // Enable scrolling
                  paddingRight: "10px", // Optional padding for scrollbar space
                }}
              >
                <div style={{ width: "90%" }}>
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "20px",
                      textAlign: "justify",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {questions[currentQuestion - 1]?.prompt}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "20px",
                      textAlign: "justify",
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {questions[currentQuestion - 1]?.instruction}
                  </Typography>
                  <span
                    style={{
                      fontSize: "20px",
                      textAlign: "justify",
                      fontWeight: 600,
                    }}
                  >
                    {questions[currentQuestion - 1]?.question}
                  </span>
                  {questions[currentQuestion - 1]?.question_url && (
                    <Typography
                      variant="h5"
                      sx={{
                        marginBottom: "20px",
                        textAlign: "left",
                        position: "relative", // Ensure proper positioning of the image
                        overflow: "visible", // Ensure the enlarged image is not clipped
                        "& img": {
                          maxWidth: "100%",
                          height: "100%",
                          cursor: "pointer",
                          transition: "transform 0.3s ease",
                          position: "relative",
                          transformOrigin: "left center", // Keep the hover scaling aligned to the left
                        },
                        "& img:hover": {
                          transform: "scale(1.5)",
                        },
                      }}
                    >
                      <img src={imageFile} alt="Uploaded preview" />
                    </Typography>
                  )}
                </div>
                {List?.data?.questions.length > 0 ? (
                  <div
                    title="Smart Tutor "
                    style={{
                      cursor: "pointer",
                      textAlign: "end",
                      width: "10%",
                    }}
                  >
                    <div
                      style={{
                        color: "#009DFF",
                        padding: "10px 0px 3px 10px",
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: "15px",
                      }}
                    >
                      Smart Tutor
                    </div>
                    <div
                      className="ai-button"
                      onClick={() => {
                        setChatbotOpen(true);
                        dispatch(
                          successChatbot(
                            questions[currentQuestion - 1]?.question
                          )
                        );
                      }}
                    >
                      <SvgIcon
                        component={Chatbotboticon}
                        sx={{ fontSize: "50px" }}
                        inheritViewBox
                      />
                    </div>
                    <Chatbot
                      open={chatbotOpen}
                      setOpen={setChatbotOpen}
                      questiondata={questions[currentQuestion - 1]?.question}
                      imagedata={questions[currentQuestion - 1]?.question_url}
                      passage={questions[currentQuestion - 1]?.prompt}
                      instruction={questions[currentQuestion - 1]?.instruction}
                    />
                  </div>
                ) : null}
              </Typography>
            </div>
          </Grid>
        </div>
        {List?.data?.questions.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              fontSize: "larger",
              marginTop: "30%",
            }}
          >
            No Question Found
          </div>
        ) : (
          renderQuestionOptions(
            currentQuestion,
            questions[currentQuestion - 1]?.options || [],
            questions[currentQuestion - 1]?.optionsurl || []
          )
        )}

        <div className="navigation-wrapper"></div>
        <div className="navigation-buttons">
          <Pagination
            count={questions?.length}
            variant="outlined"
            siblingCount={4} // Display all sibling pages
            boundaryCount={1} // Show all boundary pages
            page={currentQuestion} // Set the current page to be visible
            onChange={(event, value) => setCurrentQuestion(value)} // Update `currentQuestion` on change
            renderItem={(item: any) => {
              if (item.type === "previous" || item.type === "next") {
                return null;
              }
              if (item.type === "page") {
                return (
                  <Button
                    sx={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      color: "black",
                      backgroundColor:
                        item?.page === currentQuestion ? "blue" : "#E7E7E7",
                      minWidth: "unset",
                      padding: 0,
                      marginLeft: "5px",
                      marginTop: "3px",
                    }}
                    onClick={() => setCurrentQuestion(item?.page)}
                  >
                    {item.page}
                  </Button>
                );
              }
              return <PaginationItem {...item} />;
            }}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              className="buttonnameprevious"
              sx={{ color: "#009DFF" }}
              onClick={() =>
                setCurrentQuestion((prev) => Math.max(prev - 1, 1))
              }
              disabled={currentQuestion === 1}
            >
              Previous
            </Button>

            {currentQuestion === questions?.length ? (
              <Button
                className="buttonnamenextresport"
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsExamOver(false);
                  navigate("/examresult", { state: view_id }); // Replace with your target route
                }}
              >
                Proceed to Report
              </Button>
            ) : (
              <Button
                className="buttonnamenext"
                sx={{ zIndex: 1111 }}
                onClick={() => {
                  setCurrentQuestion((prev) =>
                    Math.min(prev + 1, questions?.length)
                  );
                }}
                disabled={currentQuestion === questions?.length}
              >
                Next
              </Button>
            )}
            <Button
              className="buttonnamenextresport"
              sx={{ zIndex: 1111 }}
              onClick={() => {
                setIsExamOver(false);
                navigate("/home"); // Replace with your target route
              }}
            >
              Back to Home
            </Button>
          </div>
        </div>

        {/* section dialog */}
        <Drawer open={open} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 450, padding: "60px 30px" }}
            role="presentation"
            onClick={toggleDrawer(false)}
          >
            <div className="boxDialog">Change Section </div>
            <div className="boxDialogparapgraph">
              1) By changing section you will be shifted to a new section .
              <br></br>
              2) If you are sure about section changes , selects from below{" "}
            </div>
            <div className="listissection">
              {List?.data?.questions_by_type.map((item: any) => (
                <div>
                  <div
                    className="listtype"
                    key={item.question_type_id}
                    onClick={() => handleListItemClick(item?.question_type_id)}
                    style={{
                      cursor: "pointer",
                      color:
                        questions[currentQuestion - 1]?.question_type ===
                        item?.question_type_name
                          ? "#009DFF"
                          : "#828282",
                      borderRadius: "10px",
                    }} // Optional: Change cursor to pointer for better UX
                  >
                    <Brightness1Icon
                      fontSize="small"
                      sx={{
                        marginRight: "10px",
                        color:
                          questions[currentQuestion - 1]?.question_type ===
                          item?.question_type_name
                            ? "#009DFF"
                            : "#828282",
                      }}
                    />
                    {item?.question_type_name}
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </Box>
        </Drawer>

        <AlterDialog
          alterheading={"View Solution"}
          alterableName={"Get Back to Report Page"}
          open={isExamOver}
          setOpen={setIsExamOver}
          buttonname={
            <>
              <ButtonField
                className="alter_button"
                onClick={() => {
                  setIsExamOver(false);
                  navigate("/examresult", { state: view_id }); // Replace with your target route
                }}
              >
                Ok
              </ButtonField>
            </>
          }
        />
      </div>{" "}
    </>
  );
};

export default ReViewExam;
